import { BlockProxy, Timespan } from '@strategies/react-timeline';
import { override, makeObservable } from 'mobx';
import { stores as _stores } from '@strategies/stores';

import DashiProject from './Project';


export default class DashiBlockProxy extends BlockProxy {

    private readonly project: DashiProject;
    
    constructor(project: DashiProject) {
        super();

        this.project = project;
        makeObservable(this);
    }

    @override
    get selected() {
        return this.project.selected;
    }

    setSelected(selected: boolean) {
        this.project.setSelected(selected);
    }

    @override
    get timespan() {
        return {
            start: this.project.timestamps.design,
            end: this.project.timestamps.end,
        }
    }

    setTimespan({ start, end }: Timespan) {
        this.project.setTimestamps({
            design: start,
            end
        });
    }

    @override
    get y() {
        return this.project.timelineY;
    }

    setY(y: number) {
        this.project.setTimelineY(y);
    }

    destroy() {
        _stores.persist.remove(this.project);
    }

}
