import { time } from '@strategies/react-timeline';


const CUBConfig: any = {

    client: 'Platform Demonstration',

    project: 'Dashi Demo',
    contact: 'strategies@sasaki.com',

    userGuide: 'https://docs.google.com/document/d/e/2PACX-1vRnUvHasEt15UVAg6uS9Ns61Db5Ftxy8tbpiUqehSp6ETW-c99K6C3cJ9_b7Ds9j6e8AfheMdsG-Kok/pub',

    colorBy: {
        use: 'SpaceTypeColors',
        type: 'ProjectTypeColors',
        funding: 'ProjectFundingColors',
        campus: 'CampusColors',
        phase: 'PhaseColors',
    },

    phases: {
        'Phase 1 (2021-2031)': [0, time.YEAR * 10],
        'Phase 2 (2031-2041)': [time.YEAR * 10, time.YEAR * 10 * 2],
        'Phase 3 (2041-2051)': [time.YEAR * 10 * 2, time.YEAR * 10 * 3],
        'Phase 4 (2051+)': [time.YEAR * 10 * 3, time.YEAR * 10 * 4],
    },

    startYear: 2021,
    totalPeriods: (2054 - 2021) * 4,

    CampusColors: {
        "Main Campus": '#30378E',
        "East Campus": '#2C7951',
        "North Campus": '#FF7700',
        "South Campus": '#FFC000',
    },

    PhaseColors: {
        'Phase 1 (2021-2031)': '#003399',
        'Phase 2 (2031-2041)': '#3969d3',
        'Phase 3 (2041-2051)': '#7ca2f4',
        'Phase 4 (2051+)': '#c0e0fd',
    },

    ProjectFundingColors: {
        'Donor Funded': '#84cf50',
        'Capital General': '#7f3f5a',
        'Capital Auxiliary': '#b61e5e',
        'P3 Partnership': '#7c4cd1',
        'State/Federal': '#e39207',
        'Unspecified': '#7F7075',
    },

    ProjectTypeColors: {
        "New Construction": "#42d29e",
        "Renovation": "#ffb312",
        "Demolition": "#ff5244",
    },

    SpaceTypeColors: {
        'Learning': '#1F9BF8',
        'Administration': '#91473B',
        'Student Life': '#C0008E',
        'Parking': '#D8D8D8',
        'Research': '#4FCCCA',
        'Housing - Undergraduate': '#FCD21E',
        'Housing - Graduate': '#FF7700',
        'Athletics': '#bdc19c',
        'Partnership / Community': '#B663DB',
        'Support': '#A88C74',
        'Site Infrastructure': '#808080',
    },

    whitelist: [
        "meg.campbell@louisville.edu",
        "christy.mcelfresh@louisville.edu",
        "karen.blake@louisville.edu",
        "charles.ames@louisville.edu",
        "rob.fry@louisville.edu",
    ],

};

export default CUBConfig;
