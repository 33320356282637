const firebaseConfig = {
  apiKey: "AIzaSyCnLlO0Ny9WZiXiOjPsoI-107U4nspCXwA",
  authDomain: "uofl-dashi.firebaseapp.com",
  projectId: "uofl-dashi",
  storageBucket: "uofl-dashi.appspot.com",
  messagingSenderId: "36906227849",
  appId: "1:36906227849:web:e0aab47ac1f29ff7420e58"
};

export default firebaseConfig;
