import firebase from 'firebase/app';
import 'firebase/firestore';
import { stores } from '@strategies/stores';
import { computed, observable } from 'mobx';
import { applySnapshot, getSnapshot, Model, model, modelAction, prop } from 'mobx-keystone';
import Versions from '@strategies/versions';

import pkg from '../../../package.json';
import DashiProject from '../models/Project';
import { reduceById, randomString } from '../util';


const ENV_PREFIX = '';//process.env.REACT_APP_ENV === 'production' ? '' : `${process.env.REACT_APP_ENV}_`;


@model('dashi/PersistStore')
class DashiPersistStore extends Model({
    id: prop<string>(() => randomString(20)).withSetter(),
    scenarioName: prop<string>('').withSetter(),
    description: prop<string>('').withSetter(),
    projects: prop<DashiProject[]>(() => []),
    created: prop<number>(() => Date.now()).withSetter(),
    modified: prop<number>(0).withSetter(),
    createdBy: prop<string>(''),
    version: prop<string>(pkg.version),
}) {

    db: any;
    readonly collection: string = `${ENV_PREFIX}scenarios`;
    protected versions: Versions|undefined;

    @observable
    exists: boolean = false;

    onRegister() {
        const { config } = stores;

        if (firebase.apps.length === 0) {
            firebase.initializeApp(config);
        }

        this.db = firebase.firestore();

        this.db.collection(this.collection).doc(this.id).get().then((doc: any) => {
            this.setExists(doc.exists);
        });

        this.seed();

        window.addEventListener('keydown', e => {
            if (e.ctrlKey && e.key === 's' && this.exists) {
                e.preventDefault();
                e.stopPropagation();

                console.log('saving');
                this.save();
            }
        });
    }

    getProject(id: string) {
        return this._projectsById[id] || null;
    }

    branch() {
        this.setId(randomString(20));
        this.setCreated(Date.now());
    }

    async delete(id: string) {
        const doc = await this.db.collection(this.collection).doc(id).get();

        if (doc && doc.exists) {
            doc.ref.delete();
        }

        if (id === this.id) {
            this.setExists(false);
        }
    }

    async list() {
        const collection = await this.db.collection(this.collection).get();
        return collection.docs.map((doc: any) => doc.data()).sort((a: any, b: any) => a.created < b.created ? 1 : -1);
    }

    @modelAction
    load() {
        this.db.collection(this.collection).doc(this.id).get().then((doc: any) => {
            const data = doc.data();

            if (data) {
                data.projects.forEach((project: any) => {
                    if (!project.$modelId) {
                        project.$modelId = randomString(16);
                    }
                });

                data.$modelId = this.$modelId;
                applySnapshot(this, this.versions ? this.versions.upgrade(data) : data);
                this.setExists(true);
            }
        });
    }

    newDocument() {
        const { app } = stores;

        this.branch();
        this.seed();
        app.triggerBlockSort();
        this.setScenarioName('');
        this.setDescription('');
        this.setExists(false);
    }

    @modelAction
    remove(project: DashiProject) {
        if (project.mapped) {
            project.setType("Existing");
        }
        else {
            this.projects.splice(this.projects.indexOf(project), 1);
        }
    }

    seed() {
        console.info('No seed for this dashboard');
    }

    save() {
        if (!this.exists) {
            this.setCreatedBy(stores.user.displayName);
        }

        this.setModified(Date.now());
        this.db.collection(this.collection).doc(this.id).set(getSnapshot(this));
        this.setExists(true);
    }

    @modelAction
    addProject(project: DashiProject) {
        this.projects.push(project);
    }

    @modelAction
    setCreatedBy(createdBy: string) {
        this.createdBy = createdBy;
    }

    @modelAction
    setExists(exists: boolean) {
        this.exists = exists;
    }

    @computed
    private get _projectsById() {
        return reduceById<DashiProject>(this.projects);
    }



}


export default DashiPersistStore;
