import { time } from '@strategies/react-timeline';

export function randomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function duration(a: number, b: number) {
    return Math.floor((b - a) / time.MONTH);
}

export function reduceById<T extends { id: string }>(arr: T[]): { [key: string]: T } {
    return [ ...arr ].reduce((a,b) => {
        a[b.id] = b;
        return a;
    }, {} as { [key: string]: T });
}


export function add(accumulator: number, a: number) {
    return accumulator + a;
}

export type KVP = { [key: string]: number;}

export function addKVPs(accumulator: KVP, a: KVP):KVP {
    const ans: KVP = {...accumulator};
    Object.keys(a).forEach((k) => {
        if (!ans[k]) ans[k] = 0;
        ans[k] += a[k];
    });
    return ans;
}

export function totalKvp(kvp:KVP) {
    let ans:number = 0;
    Object.keys(kvp).forEach((k) => {
        ans += kvp[k];
    });
    return ans;
}

export function total<T>(arr: T[], reducer:(accumulator: T, a: T) => T) {
    return arr.map((v, i, data) => {
        return data.slice(0, i + 1).reduce(reducer)
    });
}

export function sumArrays<T>(arrays:T[][], reducer:(accumulator: T, a: T) => T) {
    const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
    const result = Array.from({ length: n });
    return result.map((_, i) => arrays.map(xs => xs[i]).reduce(reducer));
}

export function cumulative<T>(arr: T[], reducer:(accumulator: T, a: T) => T) {
    return arr.map((v, i, data) => {
        return data.slice(0, i + 1).reduce(reducer)
    });
}

/**
 * Chunks a larger array into smaller arrays of a given size
 *
 * @param arr - the input array to be chunked
 * @param size - the size of each chunk
 * @returns a new 2D array with each internal array being of length 'size'
 *
 */
export function chunk<T>(arr: T[], size: number): T[][] {
    return [...Array(Math.ceil(arr.length / size))].map((_, i) =>
        arr.slice(size * i, size + size * i)
    );
}

export function validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}



//LINKS TO TS PLAYGROUND fiddles for testing array and KVPs

//totals and chunking
//https://www.typescriptlang.org/play?#code/GYVwdgxgLglg9mABAQwCaoBTIhEBbEAG2SjgCcAuRMfAIwFMyAaFKmvBsgSkQG8AoREMRl6UEGSTZcBYqTKIA1CgDc-AL78ICAM5REMoiRgA3eogC8iLGUrtOAbQC6PCwD4UtgHR5kABwwMExYYFlQSZFcPAWERMQkkcKhkLx1CGAh6DAAGEKVEAEYuL1FUEEysdC4NatBIWAQDAAtwAGsAHgAVNxtKRE7nFh0YAC96NjpGLioBp2c+QTjxSUQHL3WAQVtkAE8MAFkSJq9MmEJer0J6MABzKCbEAHpEYbGuLicff0CAfRCoxZCZDeNIZLKvcwAKkQoReo3MyghiGhMGqQi4ak0Wl0+lIyUIOksq1yJKYBTJTAATBTcuTSfSKdSAKxUpgM9lszkc8k8xmcgDMAqFtIpvI5pLpNJpTn4dWg8CQeOQBIAwoQQHpGABNejAjDTaiTMjzGJLBLNNoYJUElhM4q+AJ4BD3QnuRBOsAukr0MoVNCoFjZd6Y-iy8DyxqGOSmeidOD4nT6iYcRgmxaiZZIKPGMxW+PKnQYjShsP1BUGfBGWBmOMJtUaqDa3VkJOGlPGpwLIQZ83Z6tZa06euasg6vXB4uh7RgHRwK6XOA3DAAKQAygB5AByqSgZBgtxgwD2g+HjdHzf1E+xM7n9AXS7XW53e4PR4wfZjtYLl6L19n88IRcVw3bc9BfG5Dz2D8a3zVV1RHMcW3eDEgA

//array sums and KVPs
//https://www.typescriptlang.org/play?#code/GYVwdgxgLglg9mABAQwCaoBTIhEBbEAG2SjgCcAuRMfAIwFMyAaFKmvBsgSkQG8AoREMRl6UEGSTZcBYqTKIA1CgDc-AL79+UAJ4AHeogDSANQAKiALx9EAbQDW9HVQDOUMjDABzALps6jCqaoJCwCCjopmYuWDj4RCTkVFEsyMnmXBRRfILCEAhuKGAu6RbWvAB0VdLxcuTqasKIAPK0AFb00BWOOjHIXBXA5ACi2AAWGBj2PJYAfDlNTTDAiBgAhMjFDj48my7bVogADI2LQnsHitbI26dC6lx3ImISUsVqmvwh0PBIpFDIQgAHgAKrMsGRKIgQbYfCxRKgQBBGBRYjIEvIqCDUliZvMQTwBE1ROJJChIRU8Mg9JMAG4sGAsVAkfpWeZEs7PUlIZkAiouQgwZEYI4MpSIACMAwRSPoGBlyO4uXujw0Wm+YSQLnwAEFIcheqDwch9b0KDCfLD4fREYrUTVZIkodjWNC8dCmfRgMgiFAQaiuHMCQtEPlilBqIcTWQDS4Kgq5RgqQAPFjJlzugCyJDGlOQyaT+bTccI9G8UDGXBYR1VQjDhVE2sIEesepjOkGZDgeAwvEQpfLYzYiAepxJr2eTageZpGAA+gz3dHYzOMOm2Yh07YYD5EAAfPeIVBen3NkEYLjSm2y+XXxWXj5aesRvRdjrQFxRLLmWGww62ZU7F4OBgGAIV6CoCV1BYYDQPAyDoL4ECwORBCYOQ+DJUQ2CUIgrC4UA2wcMwqD0Lg1CsJg7DiFoKgACZqOQWjEAYgimiIjCKNIpDyLw7jiK47DOLwhiyNwhCCJ8J8ChfN9OigFx-1yWxRVUpgJXUti7DU0UNIlLSVPUoy6KYOiDLUgBmIyjkk6TijgUsKkIOAvAwbU8DbWMMFfOB3wU1J0BYC85mOS9+HrBz6Ccly3N1U0Yh8vzP3MALUCiFwgvdXsHkvIA
