import {computed, override, toJS} from 'mobx';
import {time} from '@strategies/react-timeline';
import { stores } from '@strategies/stores';
import {ExtendedModel, model, modelAction, prop} from 'mobx-keystone';

import DashiPeriod from '../../../core/models/Period';
import DashiProject, {IProjectData, TimelineStatus} from '../../../core/models/Project';
import {formatDollars} from "../../../core/ui/TimelinePanel/ProjectList";
import {KVP} from "../../../core/util";


export interface ICUBProjectData extends IProjectData {
    funding: string;
    _campus: string;
}

@model('cub/Project')
class Project extends ExtendedModel(DashiProject, {
    funding: prop<string>(),
    gsf: prop<number>(65),
    escalation: prop<number>(4),
    escalationYear: prop<number>(2021),
}) {

    @modelAction
    setEscalation(escalation: number) {
        this.escalation = escalation;
    }

    @modelAction
    setEscalationYear(year: number) {
        this.escalationYear = year;
    }

    @modelAction
    setFunding(funding: string) {
        this.funding = funding;
    }

    @modelAction
    setGsf(gsf: number) {
        this.gsf = gsf;
    }

    @computed
    get compoundedEscalation() {
        const {startYear} = stores.config;
        const years = Math.max(Math.floor(this.timestamps.start / time.YEAR) - (this.escalationYear - startYear), 0);

        let compounded = .025;
        for (let year = 0; year < years; year++) {
            const escalation = (year + this.escalationYear === startYear) ? .035 : this.escalation / 100;
            compounded = -1 + (1 + compounded) * (1 + escalation);
        }

        return compounded;
    }

    @computed
    get escalatedProjectCost() {
        return this.metrics['Cost'].cost * (1 + this.compoundedEscalation);
    }

    @computed
    get escalatedConstructionCost() {
        return this.metrics['Cost'].constructionCost * (1 + this.compoundedEscalation);
    }

    @computed
    get use() {
        const {before, after} = this.metrics['SpaceType'];

        if (this.type === 'Renovation' || this.type === 'New Construction') {
            return Object.keys(after)[0];
        }
        else {
            return Object.keys(before)[0];
        }
    }

    @override
    get color() {

        if (this.isExcluded) {
            return '#dfdfdf';
        }
        if (this.type === "Existing") {
            return '#dfdfdf';
        }
        const {config, app} = stores;
        // @ts-ignore
        const color = config[config.colorBy[app.colorMode]][this[app.colorMode]] || '#ff00ff';//highlight in magenta (this color should never show)

        return color;
    }

    @override
    get filteredOut(): boolean {
        const {projects} = stores.app;
        return this.type !== 'Existing' && projects.indexOf(this) < 0;
    }

    @override
    get visible() {
        if (!this.isExcluded) {
            if (this.type === "Demolition") {
                return this.timelineStatus !== TimelineStatus.Completed;
            } else if (this.type === 'New Construction') {
                return this.timelineStatus !== TimelineStatus.NotStarted
            }
        }

        return true;
    }

    @computed
    get asf() {
        return Math.max(this.metrics['SpaceType'].after[this.use] || 0, this.metrics['SpaceType'].before[this.use] || 0);
    }

    @computed
    get chartableUse(): KVP[] {
        //note that program uses can change before and after a project's construction
        //e.g. a renovation project could convert a dorm to offices

        const before = toJS(this.metrics['SpaceType'].before);//NOTE: using toJS to make debugging easier - code should be fine without...
        const after = toJS(this.metrics['SpaceType'].after);

        const statusMap = {
            [TimelineStatus.NotStarted]: this.type === 'Demolition' || this.type === 'Renovation' ? before : {},
            [TimelineStatus.Underway]: {},
            [TimelineStatus.Completed]: this.type === 'New Construction' || this.type === 'Renovation' ? after : {},
        };
        return this.timeline.map(status => statusMap[status]);
    }

    @computed
    get chartableASF() {
        const statusMap = {
            [TimelineStatus.NotStarted]: this.type === 'Demolition' || this.type === 'Renovation' ? this.asf : 0,
            [TimelineStatus.Underway]: 0,
            [TimelineStatus.Completed]: this.type === 'New Construction' || this.type === 'Renovation' ? this.asf : 0,
        };
        return this.timeline.map(status => statusMap[status]);
    }

    @computed
    get begunASF() {
        let statusMap = {
            [TimelineStatus.NotStarted]: 0,
            [TimelineStatus.Underway]: this.asf,
            [TimelineStatus.Completed]: this.asf,
        };
        return this.timeline.map(status => statusMap[status]);
    }

    @computed
    get chartableCost(): number[] {
        const {app} = stores;
        const {startPeriod} = this;

        return app.periods.map((period: DashiPeriod) => {
            return (period.i === startPeriod) ? this.escalatedProjectCost : 0;
        })
    }

    @computed
    get campus() {
        if (this._campus !== '') {
            return this._campus;
        } else {
            const {projectsByCanvas} = stores.colorizer;

            let campus = '';
            Object.entries(projectsByCanvas).some(([canvas, projects]) => {
                //TODO fix this to work with new structure // if ((projects as string[]).indexOf(this.id) !== -1) {
                //     campus = canvas;
                //     return true;
                // }
                return false;
            });

            return campus;
        }
    }

    @modelAction
    undo() {
        if (this.snapshots.length > 0) {
            const snapshot: any = this.snapshots.pop();

            this.setName(snapshot.name);
            this.setCampus(snapshot.campus);
            this.setType(snapshot.type);
            this.setTimestamps(snapshot.timestamps);

            Object.keys(this.metrics).forEach((metric: string) => {
                this.setMetric(metric, snapshot.metrics[metric]);
            });

            this.setFunding(snapshot.funding);
            this.setGsf(snapshot.gsf);
        }
    }

    @override
    get cardInfo(): { label: string, value: string }[] {
        return [
            {label: 'Cost', value: formatDollars(this.escalatedProjectCost)},
            {label: 'ASF', value: this.asf.toLocaleString()},
        ];
    }
}


export default Project;
